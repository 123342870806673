<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <div class="flex justify-end mb-4">
                <a
                    :href="`https://kruizy-rossii.com/${form.slug}/`"
                    target="_blank"
                    class="btn btn-outline-secondary w-30"
                    :class="{ 'cursor-wait pointer-events-none opacity-50': loading }"
                >
                    Перейти на страницу
                </a>
            </div>

            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="relative col-span-6">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.form.name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.name.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="relative col-span-6">
                    <label for="form-slug" class="form-label">
                        Ссылка
                        <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.slug.$errors.length" for="form-slug" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-slug"
                        v-model="form.slug"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6">
                    <label for="category-seo-block-title" class="form-label">Заголовок сео блока</label>
                    <input
                        id="category-seo-block-title"
                        v-model="form.seo_block_title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-12">
                    <label for="category-seo-block-description" class="form-label">Описание сео блока</label>
                    <CustomWyswig
                        id="category-seo-block-description"
                        v-model="form.seo_block_description"
                        class="form-control w-full"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label font-bold">Кастомные поля</label>
                    <TeamsTable v-model="seo" :meta="null" :headers="seoHeaders" />
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link :to="{ name: 'page-categories' }" class="btn btn-secondary w-24 mr-2 mb-2">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="saveAndQuit">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="saveAndEdit">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { pageCategoriesMixin } from '@/mixins/form/page-categories-mixin';
import Preloader from '@/components/preloader/Main';
import { errorResponse } from '@/mixins/form/form-mixin';
import TeamsTable from '@/components/tables/TeamsTable';
import CustomWyswig from '@/components/base/custom-wyswig/Main.vue';
import { helper as $h } from '@/utils/helper';

export default {
    name: 'EditPageCategory',
    components: { CustomWyswig, Preloader, TeamsTable },
    mixins: [pageCategoriesMixin, errorResponse],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                name: { required },
                slug: { required },
            },
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Редактировать категорию');

        await this.fetchCategory();
    },
    methods: {
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;
            try {
                const { data } = await this.axios.patch(`/page-categories/${this.$route.params.id}`, this.form);
                this.$notify(data.message);
                this.loading = false;
                return true;
            } catch (ex) {
                this.errorResponse(ex);
                this.loading = false;
                return false;
            }
        },
        async saveAndQuit() {
            const success = await this.save();
            if (success) this.$router.push({ name: 'page-categories' });
        },
        async saveAndEdit() {
            await this.save();
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
